.button {
  background-color: #339933;
  color: white;
  border: none;
  text-decoration: none;
  font-size: 14px;
  padding: 8px 32px;
  border-radius: 5px;
  margin-bottom: 4px;
}

input[type=number] {
  background-color: #EEEEEE;
  box-sizing: border-box;
  padding: 6px 10px;
  font-size: 12px;
  border: 2px solid #339933;
  border-radius: 5px;
  margin-bottom: 4px;
}

p {
  font-size: 12px;
}
