@media only screen and (orientation: landscape) {
  .grid-container {
    display: grid;
    grid-template-rows: min-content;
    grid-template-columns: repeat(12, [col-start] 1fr);
    gap: 8px;
    height: 100%;
    width: 100%;
    max-width: 1440px;
    margin: auto;
  }
}

.item_header {
  grid-column: col-start / span 12;
  text-align: center;
}

.item_data {
  grid-column: col-start / span 3;
}

.item_chart {
  grid-column: col-start 4 / span 9;
  height:60vh;
}
